<template>
    <div>
        <div class="qr-top">
            <div class="qr-back">
                <vue-qr v-if="qrInfo" :text="qrInfo.url" :size="200"></vue-qr>
            </div>
        </div>
        <div>
            <el-button v-show="showRefresh" @click="getQr">刷新</el-button>
        </div>
        <div class="qr-msg">
            {{message}}
        </div>
        <div>
            <div class="qr-bottom-info">
                <div class="qr-bottom-line"></div>
                <div class="qr-bottom-img">
                    <img src="@/assets/image/admin-login.png">
                </div>
                <div class="qr-bottom-line"></div>
            </div>
            <div>
<!--                <el-button class="qr-bottom-btn" @click="toAdminLogin">用户名密码登录</el-button>-->
            </div>
        </div>
    </div>
</template>

<script>
    import vueQr from 'vue-qr'

    var timer = undefined
    export default {
        name: "QrLogin",
        components: {
            vueQr
        },
        data() {
            return {
                qrInfo: undefined,
                message: '',
                showRefresh: false
            }
        },
        created() {
            this.getQr()
        },
        methods: {
            getQr() {
                this.showRefresh = false
                this.$httpUtil.get(this.$urlConstant.auth.getQr, (res) => {
                    this.qrInfo = res.data
                    this.startChecker()
                }, this)
            },
            startChecker() {
                timer = setInterval(this.check, 1000)
            },
            check() {
                this.$httpUtil.getGetter(this.$urlConstant.auth.checkQr + this.qrInfo.qrscene).then(res => {
                    if (res.data.status === 200) { // 登录成功
                        clearInterval(timer)
                        //判断是否是湘雅的运营者或管理员
                        console.log(process.env.VUE_APP_SITE_ID)
                        console.log(res.data.data.siteId)
                        if (process.env.VUE_APP_SITE_ID === res.data.data.siteId) {
                            this.$sess.login(res.data)
                            this.$router.push({name:'MarkList'})
                        }else {
                            this.$message.error('没有权限')
                        }
                    } else if (res.data.status === -5004) { //二维码过期
                        clearInterval(timer)
                        this.message = res.data.message
                        this.showRefresh = true
                    } else if (res.data.status === -5005) { //等待用户扫描二维码
                        this.message = '手机微信扫码,关注后登录'
                    } else if (res.data.status === -5006) { //等待用户授权
                        this.message = '请在手机上完成授权操作'
                    } else if (res.data.status === -5007) { //检查二维码扫描情况失败
                        this.message = res.data.message
                        this.showRefresh = true
                    } else {
                        clearInterval(timer)
                        this.message = res.data.message
                        this.$httpUtil.showErrorMessage(this,res.data.message)
                    }
                }).catch((error) => {
                    this.$httpUtil.doError(error, this);
                })
            },
            toAdminLogin() {
                this.$router.push({name:'Login'})
            }
        }
    }
</script>

<style scoped>
    .qr-top{
        width: 100vw;
        display: flex;
        justify-content: center;
        padding-top: 60px;
    }
.qr-back{
    width: 220px;
    height: 220px;
    background: #EEEEEE;
    border: 1px solid #D3D3D3;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
    .qr-msg{
        font-family: SourceHanSansCN-Medium;
        font-size: 15px;
        color: #000000;
        letter-spacing: 1.17px;
        text-align: center;
        margin-top: 10px;
    }
    .qr-bottom-info{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 90px;
    }
    .qr-bottom-line {
        height: 1px;
        width: 120px;
        background-color: #38B0B4;
        margin: 0 10px;
    }
    .qr-bottom-img{
        height: 40px;
        width: 40px;
    }
    .qr-bottom-img img {
        height: 35px;
        width: 35px;
        object-fit: cover;
    }
    .qr-bottom-btn{
        font-size: 12px;
        color: #595959;
        letter-spacing: 0.7px;
        text-align: center;
        border: unset;
    }
</style>
